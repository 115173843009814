<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div v-if="!user.loggedIn">
            <div class="card-header"></div>
            <div class="card-body">
              <div class="my-4">
                <p>Your session has expired.</p>
                <button @click.prevent="logOut()" class="btn btn-primary">
                  Back to Login
                </button>
              </div>
            </div>
          </div>
          <div v-if="user.loggedIn">
            <div class="card-header">
              Welcome, {{ user.data.email }}
            </div>
            <div class="card-body">
              <div class="">
                <!-- You are logged in! -->
                <div class="my-4">
                  <button
                    @click.prevent="
                      signOut();
                      disconnectDatabase();
                    "
                    class="btn btn-primary"
                  >
                    Log Out
                  </button>
                </div>
                <div class="my-4">
                  <button
                    class="btn btn-primary"
                    @click="connectDatabase"
                    :disabled="isConnected"
                  >
                    Connect
                  </button>
                  <button
                    class="btn btn-primary"
                    style="margin-left: 10px"
                    @click="disconnectDatabase"
                    :disabled="!isConnected"
                  >
                    Disconnect
                  </button>
                </div>
                <div class="my-4">
                  <button
                    class="btn btn-primary"
                    @click="calibrateCan"
                    :disabled="!isConnected || canCalibrated"
                  >
                    Calibrate Can
                  </button>
                  <button
                    class="btn btn-primary"
                    style="margin-left: 10px"
                    @click="startQuiz"
                    :disabled="!quizCanBegin || quizHasStarted"
                  >
                    Start Quiz
                  </button>
                </div>
                <div class="my-4">
                  <div id="wave">
                    <transition name="wave">
                      <div v-if="connectionRunning">
                        <span class="dot"></span>
                        <span class="dot"></span>
                        <span class="dot"></span>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <transition name="quiz">
          <div class="card" v-if="quizHasStarted" style="margin-top: 20px">
            <div>
              <div class="card-header">THE QUIZ</div>
              <div class="card-body">
                <div class="" role="alert">
                  <div class="my-4">
                    <div v-for="(question, index) in extJson" :key="index">
                      <div class="question">
                        <transition name="question">
                          <div v-if="index + 1 === questionIndex">
                            {{ index + 1 }}) {{ question }}
                            <div class="answer-buttons">
                              <button
                                class="btn btn-primary"
                                :disabled="!buttonsActive"
                                @click="
                                  storeLocalResult(true);
                                  notifyCan();
                                "
                              >
                                YES</button
                              ><button
                                class="btn btn-primary"
                                :disabled="!buttonsActive"
                                @click="
                                  storeLocalResult(false);
                                  notifyCan();
                                "
                              >
                                NO
                              </button>
                            </div>
                            <div
                              style="margin-top: 20px"
                              v-if="subButtonsActive"
                            >
                              <p>The can says {{ canResult }}</p>
                              <button
                                class="btn btn-primary"
                                @click="triggerCan(false)"
                              >
                                True
                              </button>
                              <button
                                class="btn btn-primary"
                                @click="triggerCan(true)"
                              >
                                False
                              </button>
                            </div>
                          </div>
                        </transition>
                      </div>
                    </div>
                    <transition name="question">
                      <div class="my-4">
                        <div
                          v-if="questionIndex === extJson.length + 1"
                          class="question"
                        >
                          Thanks for participating!!<br />
                          Send results by email?
                          <input
                            type="checkbox"
                            id="sendEmail"
                            name="sendEmail"
                            ref="optin"
                            v-model="optin"
                          />
                          <div v-if="optin">
                            <div class="my-4">
                              <form>
                                <div class="form-group row">
                                  <label
                                    for="email"
                                    class="col-md-4 col-form-label text-md-right"
                                    >Email</label
                                  >
                                  <div class="col-md-6">
                                    <input
                                      v-model="emailaddress"
                                      ref="contestantEmail"
                                      id="email"
                                      type="email"
                                      class="form-control"
                                      name="email"
                                      value
                                      required
                                      autofocus
                                    />
                                  </div>
                                </div>
                              </form>
                            </div>
                            <div class="answer-buttons">
                              
                              <button
                                :disabled="!emailDisabled"
                                type="email"
                                class="btn btn-primary"
                                @click="
                                  composeEmail()
                                "
                              >
                                EMAIL RESULT
                              </button>
                            </div>
                          </div>
                          <div class="answer-buttons">
                              <button
                                class="btn btn-primary"
                                @click="resetDatabase()"
                              >
                                RESET
                              </button>
            
                            </div>
                        </div>
                      </div>
                    </transition>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
        <div v-if="showDebug === true">
          <p>{{ questionIndex }}</p>
          <div v-for="(result, index) in results" :key="index">
            {{ result.answer }}
          </div>
          <div v-for="(result, index) in resultsFromCan" :key="index">
            {{ result.answer }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const apikey = "md-bCXWNOprZN_LbjSjsDqkAw";
const mailchimp = require("@mailchimp/mailchimp_transactional")(apikey);

/* this function deployed from the methods object. */
async function sendmail(message) {
  const response = await mailchimp.messages.send({
    message,
  });
  console.log(response);
  router.push("/finish");
}

import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import { auth } from "../firebaseConfig";

import { getDatabase, ref, set, onValue } from "firebase/database";
import { initializeApp } from "firebase/app";
import router from "@/routes";

const firebaseConfig = {
  apiKey: "AIzaSyC7XtXUIzAfV9DpyzcNrT1fNbXzXhjpU04",
  authDomain: "simply-spiked-testbed.firebaseapp.com",
  databaseURL: "https://simply-spiked-testbed-default-rtdb.firebaseio.com",
  projectId: "simply-spiked-testbed",
  storageBucket: "simply-spiked-testbed.appspot.com",
  messagingSenderId: "123182010112",
  appId: "1:123182010112:web:b23e842933e40a321cade5",
  measurementId: "G-ZDX5MS0SSN",
};

// Initialize Firebase.
const app = initializeApp(firebaseConfig);
// Initialize Realtime Database and get a reference to the service.
const database = getDatabase(app);

export default {
  name: "DashboardComponent",
  methods: {
    logOut() {
      location.href = "/";
    },
    composeEmail() {
      set(ref(database, `${this.user.data.uid}/quiz/quizStarted`), false);
      set(ref(database, `${this.user.data.uid}/quiz/quizState`), 0);
      let msgcontent =`An email was sent to ${this.$refs.contestantEmail.value}\n`;
      /* construct simple text email */
      this.results.forEach((question, index) => {
        msgcontent += `You were asked: ${this.extJson[index]} \nYou answered: ${question.answer}! \nThe can said: ${this.resultsFromCan[index].answer}\n`;
      });
      const emailcontent = {
        from_email: "liedetector@simplyspikedliedetector.com",
        subject: "Your Simply Spiked Lie Detector Results!",
        text: msgcontent,
        to: [
          {
            email: "hugh@simplyspikedliedetector.com",
            type: "to",
          },
        ],
      };
      sendmail(emailcontent);
    },
    /* This function loads external json 
    so client can update questions. */
    loadExternalJson() {
      let rand = Math.floor(Math.random() * 99999999);
      this.isLoading = true;
      fetch(`/json/questions.json?v=${rand}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((data) => {
          this.isLoading = false;
          const results = [];
          data.forEach((question) => {
            results.push(question.q);
          });
          this.extJson = results;
        })
        .catch((error) => {
          this.error = "Failed to fetch data, please try again later";
          this.isLoading = false;
          console.warn(error);
        });
    },
    /* Stores the users answer in the reuslts array. */
    storeLocalResult(bool) {
      this.results.push({ answer: bool });
    },
    /* Sets quizState to '2' - this informs the can to take a reading 
    when the can finishes it sets the quizState to '3' */
    notifyCan() {
      set(ref(database, `${this.user.data.uid}/quiz/`), {
        quizStarted: true,
        quizState: 2,
      });
      this.quizState = 2;

      setTimeout(() => {
        console.log(`!!!!!!!!!!!${this.questionIndex}  ${this.extJson.length} `);
        if (this.questionIndex === this.extJson.length) {
          set(ref(database, `${this.user.data.uid}/calibrate/`), {});
          set(ref(database, `${this.user.data.uid}/quiz/quizStarted`), false);
          set(ref(database, `${this.user.data.uid}/quiz/quizState`), 0);
        }

      }, 1000);
    },
    /* Sends a user chosen signal to the can. */
    triggerCan(bool) {
      if (this.questionIndex === this.extJson.length) {
        set(ref(database, `${this.user.data.uid}/quiz/quizStarted`), false);
        set(ref(database, `${this.user.data.uid}/calibrate/`), {
          
          calibrate: false,
          isCalibrated: false,
        });
      }
      set(ref(database, `${this.user.data.uid}/can/trigger`), bool);
      set(ref(database, `${this.user.data.uid}/quiz/quizState`), 4);
    },
    startQuiz() {
      /* sets 'quizHasStarted' to true,
      which enables the quiz UI elements. 
      Send a Signal to the can that the quiz has started. */
      this.quizHasStarted = true;
      set(ref(database, `${this.user.data.uid}/quiz/`), {
        quizStarted: true,
        quizState: 0,
      });
      /* Start listening for can responses. */
      this.waitForQuizState();
    },
    /* Looks for signal back from the lie detector, so we can determine
    what step we're on and how to proceed. */
    waitForQuizState() {
      let canState = ref(database, `${this.user.data.uid}/quiz/quizState`);
      onValue(canState, (snapshot) => {
        let data = snapshot.val();
        if (data === 1) {
          this.quizState = 1;
        }
        if (data === 3) {
          const resultFromCan = ref(
            database,
            `${this.user.data.uid}/reading/result`
          );
          onValue(resultFromCan, (snapshot) => {
            const data = snapshot.val();
            /* from the can boolean 'true' means you lied, 'false' means you told the truth. */
            if (data === true) {
              this.canResult = " you lied!";
            } else {
              this.canResult = " you told the truth!";
            }
            /* update resultsFromCan array. */
            this.resultsFromCan.push({ answer: this.canResult });
          });
          this.quizState = 3;
        }
        if (data === 3) {
          /* proceed to the next question. */
          this.quizState = 0;
          /* let the can know the current cycle is over. */
          set(ref(database, `${this.user.data.uid}/quiz/quizState`), 0);
          this.questionIndex = this.questionIndex + 1;
        }
      });
    },
    resetDatabase() {
      /* Reset everything after the quiz ends. */
      set(ref(database, `${this.user.data.uid}/application/`), {});
      set(ref(database, `${this.user.data.uid}/calibrate/`), {});
      set(ref(database, `${this.user.data.uid}/quiz/`), {
        quizStarted: false,
        quizState: 0,
      });
      this.quizCanStart = false;
      this.quizHasStarted = false;
      this.appIsOnline = false;
      this.questionIndex = 1;
      this.quizState = 0;
      this.results = [];
      this.canCalibrated = false;
      this.resultsFromCan = [];
      this.canResult = "";
      /* push the app to the restart page. */
      router.push("/finish");
    },
    connectDatabase() {
      this.connectionRunning = true;
      setTimeout(() => {
        this.connectionRunning = false;
      }, 1000);
      /* Sets the web app as 'online'. */
      this.appIsOnline = true;
      set(ref(database, `${this.user.data.uid}/application/`), {
        appIsConnected: true,
      });
      const appIsOnline = ref(
        database,
        `${this.user.data.uid}/application/appIsConnected`
      );
      onValue(appIsOnline, (snapshot) => {
        const data = snapshot.val();
        console.log(data);
      });
    },
    disconnectDatabase() {
      /* sets the web app as 'offine'. */
      /* if user disconnects, end calibration and disable the 'start quiz' button. */
      /* also reset various state values. */
      this.endCalibration();
      this.quizCanStart = false;
      this.quizHasStarted = false;
      this.appIsOnline = false;
      this.questionIndex = 1;
      this.quizState = 0;
      this.results = [];
      this.resultsFromCan = [];
      this.canCalibrated = false;
      this.canResult = "";
      set(ref(database, `${this.user.data.uid}/application/`), {
        appIsConnected: false,
      });
      set(ref(database, `${this.user.data.uid}/quiz/`), {});
    },
    calibrateCan() {
      /* this function sets 'calibrate' to true 
      and 'isCalibrated' to false. When the can 
      completes calibraing, it will set the 'isCalibrated' endpoint 
      to true and the 'calibrationCheck' listener will stop and the 
      'Start Quiz' button will be enabled. */
      this.connectionRunning = true;
      setTimeout(() => {
        this.connectionRunning = false;
      }, 1000);
      set(ref(database, `${this.user.data.uid}/calibrate/`), {
        calibrate: true,
        isCalibrated: false,
      });

      /* Wait for a signal back from the can that it has completed calibration */
      let canIsCalibrated = ref(
        database,
        `${this.user.data.uid}/calibrate/isCalibrated`
      );
      onValue(canIsCalibrated, (snapshot) => {
        let data = snapshot.val();
        if (data === true) {
          this.canCalibrated = true;
          this.quizCanStart = true;
        }
      });
    },
    /* Sets the signal that calibration is complete. */
    endCalibration() {
      set(ref(database, `${this.user.data.uid}/calibrate/`), {
        calibrate: false,
        isCalibrated: false,
      });
    },
  },
  mounted() {
    /* On init or if the app reloads 
    check to see if we were connected previously. */
    const appIsOnline = ref(
      database,
      `${this.user.data.uid}/application/appIsConnected`
    );
    onValue(appIsOnline, (snapshot) => {
      const data = snapshot.val();
      if (data === true) {
        this.appIsOnline = true;
      } else {
        this.appIsOnline = false;
      }
    });
    this.loadExternalJson();
  },
  computed: {
    isConnected() {
      return this.appIsOnline === true;
    },
    canIsCalibrated() {
      return this.canCalibrated === true;
    },
    quizCanBegin() {
      return this.quizCanStart === true;
    },
    buttonsActive() {
      return this.quizState === 1;
    },
    subButtonsActive() {
      return this.quizState === 3;
    },
    emailDisabled() {
      return this.emailaddress.length > 0;
    },
  },
  data() {
    return {
      optin: false,
      emailaddress: "",
      connectionRunning: false,
      showDebug: false,
      extJson: [],
      isLoading: false,
      appIsOnline: false,
      canCalibrated: false,
      quizCanStart: false,
      quizHasStarted: false,
      questionIndex: 1,
      quizState: 0,
      canResult: "",
      quiz: [
        {
          q: "Question 1...?",
        },
        {
          q: "Question 2...?",
        },
        {
          q: "Question 3...?",
        } /*,
        {
          q: "Question 4...?",
        },
        {
          q: "Question 5...",
        },
        {
          q: "Question 6...",
        },
        {
          q: "Question 7...?",
        },
        {
          q: "Question 8...?",
        },
        {
          q: "Question 9...?",
        },
        {
          q: "Question 10...?",
        }, */,
      ],
      results: [],
      resultsFromCan: [],
    };
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    auth.onAuthStateChanged((user) => {
      store.dispatch("fetchUser", user);
    });

    const user = computed(() => {
      return store.getters.user;
    });

    const signOut = async () => {
      await store.dispatch("logOut");
      router.push("/");
    };

    return { user, signOut };
  },
};
</script>

<style scoped lang="css">
div#wave {
  position: relative;
  text-align: center;
  width: 100px;
  height: 10px;
  margin-left: auto;
  margin-right: auto;
  padding: 10px 0px;
}

div#wave .dot {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 5px;
  background: #fcf093;
  animation: wave 1.3s linear infinite;
}

#wave .dot:nth-child(2) {
  animation-delay: -1.1s;
}

#wave .dot:nth-child(3) {
  animation-delay: -0.9s;
}

@keyframes wave {
  0%,
  60%,
  100% {
    transform: initial;
  }

  30% {
    transform: translateY(-15px);
  }
}
.my-4 {
  text-align: center;
}
.container {
  min-width: 90%;
}
.signal {
  font-size: 40px;
  font-weight: bold;
}

button:disabled {
  background-color: #ccc;
  border-color: #ccc;
  box-shadow: none;
  color: #3f3f3f;
  cursor: not-allowed;
}

div.question {
  font-weight: bold;
  width: 100%;
}

div.question .answer-buttons {
  padding-top: 20px;
}

div.question button {
  margin-right: 10px;
}

.question-enter-from,
.quiz-enter-from {
  opacity: 0;
  transform: translateY(-80px);
}

.question-enter-active,
.quiz-enter-active {
  transition: all 0.8s ease-out;
}

.question-enter-to,
.quiz-enter-to {
  opacity: 1;
  transform: translateY(0);
}

.question-leave-from {
  opacity: 1;
  transform: translateY(0px);
}
.question-leave-active {
  transition: all 0.3s ease-in;
}
.question-leave-to {
  opacity: 0;
  transform: translateX(80px);
}
.wave-enter-from {
  opacity: 0;
}
.wave-enter-active {
  transition: all 0.5s ease-in;
}
.wave-enter-to {
  opacity: 1;
}
.wave-leave-from {
  opacity: 1;
}
.wave-leave-active {
  transition: all 0.5s ease-in;
}
.wave-leave-to {
  opacity: 0;
}
</style>
