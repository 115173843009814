// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyC7XtXUIzAfV9DpyzcNrT1fNbXzXhjpU04",
  authDomain: "simply-spiked-testbed.firebaseapp.com",
  databaseURL: "https://simply-spiked-testbed-default-rtdb.firebaseio.com",
  projectId: "simply-spiked-testbed",
  storageBucket: "simply-spiked-testbed.appspot.com",
  messagingSenderId: "123182010112",
  appId: "1:123182010112:web:b23e842933e40a321cade5",
  measurementId: "G-ZDX5MS0SSN"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

//initialize firebase auth
const auth = getAuth()

export { app, auth }
