<template>
  <div>
    <navbar></navbar>
    <main class="py-4">
      <router-view></router-view>
    </main>
  </div>
</template>

<script>
import navbar from "./components/Navbar";
export default {
  components: {
    navbar,
  },
};
</script>

<style>
body {
  background-color: black;
}
html {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
main {
  height: 100%;
}

.my-4 {
  margin: 1rem;
}

.card {
  border: 5px solid #fcf093;
  background-color: black;
  color: #fcf093;
}

.card-header {
  background-color: #fcf093;
  color: black;
  font-weight: bold;
  text-align: center;
  font-size: 1.2rem;
}

.card-header:first-child {
  border-radius: 0;
}

.form-control {
  border: 1px solid #fcf093;
  background-color: black !important;
}

.btn-primary,
.btn-primary:hover,
.btn-primary:active,
.btn-primary::selection,
.btn-primary:focus {
  color: black;
  background-color: #fcf093 !important;
  border-color: #fcf093 !important;
  font-weight: bold;
}

.btn-primary:hover {
  transform: scale(1.1);
}
.btn-primary:disabled:hover {
  transform: scale(1);
}

input#email.form-control,
input#password.form-control {
  background-color: #fcf093 !important;
}

@media screen and (max-width: 500px) {
  .card-header {
    font-size: 1rem;
  }
}
</style>
