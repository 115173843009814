<template>
  <nav class="navbar navbar-expand-md navbar-light navbar-laravel">
    <div class="container" style="justify-content: center">
      <router-link to="/" ><img src="@/assets/lemon.png" style="width:150px;padding: 10px 0px;"/></router-link>
      <router-link to="/" ><img src="@/assets/ss-logo.png" style="width: 100%; max-width: 500px" /></router-link>
    </div>
  </nav>
</template>

<script>
export default {
  name: "NavBar",
};
</script>

<style scoped>

</style>
