<template>
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-md-8">
        <div class="card">
          <div>
            <div class="card-header">Success!</div>
            <div class="card-body">
              <div class="">
                <div class="my-4">
                  <button @click.prevent="restart()" class="btn btn-primary">
                    Again?
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { computed } from "vue";
import router from "@/routes";

export default {
  name: "FinishComponent",
  methods: {
    restart() {
      router.push("/dashboard");
    },
  },
  computed: {},
  data() {
    return {};
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const user = computed(() => {
      return store.getters.user;
    });

    const signOut = async () => {
      await store.dispatch("logOut");
      router.push("/");
    };

    return { user, signOut };
  },
};
</script>

<style scoped>
.container {
  min-width: 90%;
}

.my-4 {
  text-align: center;
}
.signal {
  font-size: 40px;
  font-weight: bold;
}

button:disabled {
  background-color: #ccc;
  border-color: #ccc;
  box-shadow: none;
  color: #3f3f3f;
  cursor: not-allowed;
}

div.question {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-weight: bold;
}
div.question button {
  margin: 0px 10px;
}
/* ENTER */
/* initial state */
.question-enter-from,
.quiz-enter-from {
  opacity: 0;
  transform: translateY(-80px);
}

/* define the transition length etc here */
.question-enter-active,
.quiz-enter-active {
  transition: all 0.8s ease-out;
}

/* end state */
.question-enter-to,
.quiz-enter-to {
  opacity: 1;
  transform: translateY(0);
}

/* LEAVE */
/* initial state */
.question-leave-from {
  opacity: 1;
  transform: translateY(0px);
}

/* define the transition length etc here */
.question-leave-active {
  transition: all 0.3s ease-in;
}

/* end state */
.question-leave-to {
  opacity: 0;
  transform: translateX(80px);
}
</style>
